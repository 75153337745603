<script>
export default {
  metaInfo: {
    title: "Not Found",
  }
};
</script>

<template>
  <section class="not-found-view">
    <div class="not-found-view__box">
      <h1 class="not-found-view__title">
        404
      </h1>

      <p class="not-found-view__txt  not-found-view__txt--subtitle">
        Page not found
      </p>

      <p class="not-found-view__txt">
        The page you are looking for might have been removed, had its name changed or doesn't exist.
      </p>

      <RouterLink
        :to="{ name: 'home' }"
        class="not-found-view__link"
      >
        main page
      </RouterLink>
    </div>
  </section>
</template>

<style lang="scss">
$box-width: 350px;
.not-found-view {
  display: flex;
  height: 100%;

  &__box {
    margin: auto;
    max-width: $box-width;
    width: 100%;
  }

  &__box,
  &__title,
  &__txt {
    text-align: center;
  }

  &__title {
    font-size: 4rem;
  }

  &__txt {
    font-size: 1rem;

    &--subtitle {
      font-size: 1.5rem;
      font-weight: 700;
    }
  }

  &__link {
    text-transform: uppercase;
  }
}
</style>
